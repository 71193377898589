import animejs from 'animejs';
export const InputAnimate = (dom, method) => {
  if (method === 'hide') {
    animejs({
      targets: dom,
      width: '0', // -> from '28px' to '100%',
      easing: 'easeInOutQuad',
      duration: 500,
      complete: () => {
        animejs({
          targets: dom,
          height: '0', // -> from '28px' to '100%',
          easing: 'easeInOutQuad',
          duration: 300,
        });
      },
    });
  } else if (method === 'show') {
    animejs({
      targets: dom,
      height: '40px', // -> from '28px' to '100%',
      easing: 'easeInOutQuad',
      duration: 300,
      complete: () => {
        animejs({
          targets: dom,
          width: '100%', // -> from '28px' to '100%',
          easing: 'easeInOutQuad',
          duration: 500,
        });
      },
    });
  }
};

export const fadeInOut = (dom, height, method, callback = () => {}) => {
  if (method === 'hide') {
    animejs({
      targets: dom,
      opacity: '0', // -> from '28px' to '100%',
      easing: 'easeInOutQuad',
      duration: 100,
      complete: () => {
        animejs({
          targets: dom,
          height: '0px', // -> from '28px' to '100%',
          easing: 'easeInOutQuad',
          duration: 100,
          complete: callback,
        });
      },
    });
  } else if (method === 'show') {
    animejs({
      targets: dom,
      height: height, // -> from '28px' to '100%',
      easing: 'easeInOutQuad',
      duration: 100,
      complete: () => {
        animejs({
          targets: dom,
          opacity: '1', // -> from '28px' to '100%',
          easing: 'easeInOutQuad',
          duration: 100,
          complete: callback,
        });
      },
    });
  }
};

export const fadeInOutSearch = (dom, method, callback = () => {}) => {
  if (method === 'hide') {
    animejs({
      targets: dom,
      opacity: '0', // -> from '28px' to '100%',
      easing: 'easeInOutQuad',
      duration: 100,
      complete: () => {
        animejs({
          targets: dom,
          width: '140px', // -> from '28px' to '100%',
          easing: 'easeInOutQuad',
          duration: 200,
          complete: callback,
        });
      },
    });
  } else if (method === 'show') {
    animejs({
      targets: dom,
      opacity: '1', // -> from '28px' to '100%',
      easing: 'easeInOutQuad',
      duration: 200,
      complete: () => {},
    });
    animejs({
      targets: dom,
      width: '300px', // -> from '28px' to '100%',
      easing: 'easeInOutQuad',
      duration: 100,
      complete: callback,
    });
  }
};
