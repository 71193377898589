import React from 'react';
import UserInfoForm from '../user-info-form';
import styles from './index.module.scss';

const UserInfoFormModalContent: React.FC<Record<string, null>> = () => {
  return (
    <section className={styles['user-info-form-modal-content-section']}>
      <h1 className={styles['user-info-form-modal-content-section-title']}>请完善基本信息</h1>
      <p className={styles['user-info-form-guide']}>
        完善基本信息，可获得
        <span>50元优惠券</span>
        同时报告下载权限从<span>3次/月</span>增至
        <span>8次/月</span>，下载权限每月月初将会重置
      </p>
      <UserInfoForm />
    </section>
  );
};
export default UserInfoFormModalContent;
