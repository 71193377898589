import React from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import { SearchIcon } from '@/components/svg';
import styles from './index.module.scss';
const { Search } = Input;

export default function SearchComponent({ height, placeholder }: { height?: number; placeholder?: string }) {
  const searchHandle = value => {
    if (!value) return;
    location.href = `/search?query=${value}`;
  };
  return (
    <Search
      placeholder={placeholder}
      allowClear
      onSearch={searchHandle}
      enterButton={<>搜索</>}
      size="large"
      className={classNames('flex-row-nowrap', 'flex-center-center', styles.searchComponent)}
      addonBefore={<SearchIcon />}
      style={{ height: height + 'px' }}
    />
  );
}
