import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Modal } from 'antd';
import ReactLoading from 'react-loading';
import { useSetState, useRequest } from 'ahooks';
import { useSelector } from 'react-redux';
import Button from '@/components/button';
import Input from '@/components/input';
import CountDown from '@/components/count-down';
import { RootState } from '@/models/index';
import { isMobilePhone } from '@/constants/regexp';
import { USER_TOKEN } from '@/constants/global';
import { IS_DEV } from '@/constants/env';
import { bindwechat, login, loginByNode } from '@/services/user';
import { getSmsCode } from '@/services/sms';
import ErrorContent from '../error-content';
import styles from './index.module.scss';

export default function BindWechat() {
  const { bindWechatVisible } = useSelector((state: RootState) => state.user);
  const [loginState, setLoginState] = useSetState({
    mobile: '',
    vcode: '',
  });
  const [canClick, setCanClick] = useState(false);
  const [loading, setLoading] = useState(false);

  const onVcodeChange = useCallback(
    value => {
      setLoginState({ vcode: value });
    },
    [setLoginState]
  );
  const onMobileChange = useCallback(
    value => {
      setLoginState({ mobile: value });
    },
    [setLoginState]
  );
  const sendCodeHandle = useCallback(async data => {
    try {
      await getSmsCode(data);
    } catch (error) {
      return error;
    }
  }, []);
  const {
    run: loginRun,
    // data,
    error,
  } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    data => {
      return IS_DEV ? login(data) : loginByNode(data);
    },
    {
      onSuccess: async res => {
        localStorage.setItem(USER_TOKEN, res.data.token);
        await bindwechat({
          unionid: res?.data?.unionid || JSON.parse(sessionStorage.getItem('_cbndata_wechat_user')).unionid,
        });
        location.reload();
        // setLoading({ accountLoading: false, mobileLoading: false });
      },
      onError: () => {
        setLoading(false);
      },
      manual: true,
    }
  );
  // useEffect(() => {
  //   dispatch({ type: 'user/fetchBindWechatVisible', payload: true });
  // }, [dispatch]);
  useEffect(() => {
    setCanClick(Boolean(loginState.mobile && loginState.vcode));
  }, [loginState.mobile, loginState.vcode, setCanClick]);
  const mobileLogin = useCallback(() => {
    if (!canClick || loading) return;
    setLoading(true);
    loginRun({ code: loginState.vcode, login_type: 'phone_code', phone: loginState.mobile });
  }, [canClick, loading, loginRun, loginState.mobile, loginState.vcode]);
  return (
    <Modal
      visible={bindWechatVisible}
      // onOk={handleOk}
      keyboard={false}
      maskClosable={false}
      closeIcon={<></>}
      // onCancel={handleCancel}
      footer={null}
      wrapClassName="bindWechatContent"
      width={440}
    >
      <form
        name="bind-wechat"
        className={classNames('flex-column-nowrap', 'flex-center-center', styles.userLoginContent)}
      >
        <div className={styles.notice}>为了您的账户安全</div>
        <div className={styles.notice}>请先绑定手机号</div>
        <Input
          className={styles.input}
          value={loginState.mobile}
          onChange={onMobileChange}
          placeholder="请输入手机号"
        />
        <Input
          className={styles.input}
          value={loginState.vcode}
          onChange={onVcodeChange}
          placeholder="请输入验证码"
          rightSideSlot={
            <CountDown
              canSend={loginState.mobile && isMobilePhone.test(loginState.mobile)}
              onSend={() => {
                sendCodeHandle({ phone: loginState.mobile, type: 'none' });
              }}
            />
          }
        />
        <ErrorContent errText={error?.message} style={{ marginTop: '8px' }} />
        <Button type={canClick ? 'normal' : 'default'} style={{ marginTop: '24px' }} onClick={mobileLogin}>
          {loading ? (
            <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadingImg} />
          ) : (
            '确认绑定'
          )}
        </Button>
      </form>
    </Modal>
  );
}
