export const throttle = (fn, delay = 100): any => {
  let timer = null;

  return function () {
    if (timer) {
      return;
    }
    timer = setTimeout(() => {
      // 执行事件的回调函数
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
      // 执行后清空定时器
      timer = null;
    }, delay);
  };
};

export const debounce = (fn, delay = 500): any => {
  let timer = null;

  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      // eslint-disable-next-line prefer-rest-params
      fn.apply(this, arguments);
      timer = null;
    }, delay);
  };
};
