import React from 'react';
import { Field } from 'rc-field-form';
import type { FieldProps } from 'rc-field-form/es/Field';
import styles from './index.module.scss';

export type LabelFieldProps = {
  label?: string;
  colon?: boolean;
  required?: boolean;
} & FieldProps;

const LabelField: React.FC<LabelFieldProps> = ({
  name,
  label = '',
  colon = true,
  required = false,
  children,
  ...restProps
}) => {
  return (
    <Field name={name} {...restProps}>
      {(control, meta, form) => {
        const childNode =
          typeof children === 'function'
            ? children(control, meta, form)
            : React.cloneElement(children as React.ReactElement, {
                ...control,
                style: { ...(meta.errors.length && { border: '1px solid #f72f66' }) },
              });
        return (
          <div className={styles.labelField}>
            {required ? <span className={styles.requiredMark}>*</span> : ''}
            <div className={styles.labelWrapper}>
              <label className={styles.label}>{label}</label>
              {colon ? '：' : ''}
            </div>
            {childNode}
            {/* <div className="input-wrapper">
              {childNode}
              <span
                className={classnames('validate-error', {
                  'validate-error--show': meta.errors.length,
                })}
              >
                {meta.errors[0]}
              </span>
            </div> */}
          </div>
        );
      }}
    </Field>
  );
};

export default LabelField;
