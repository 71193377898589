import React from 'react';
// import classNames from 'classnames';

import styles from './index.module.scss';

export default function ErrorContent({ errText, style }) {
  return (
    <>
      {errText && (
        <div className={styles.errorContent} style={style}>
          *{errText}
        </div>
      )}
    </>
  );
}
