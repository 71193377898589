import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { useSetState, useRequest } from 'ahooks';
import ReactLoading from 'react-loading';
import { RootState } from '@/models/index';
import { initStore } from '@/store';
import Input from '@/components/input';
import Tabs from '@/components/tabs';
import CountDown from '@/components/count-down';
import Divider from '@/components/divider';
import Button from '@/components/button';
import { loginInwechat } from '@/utils/helpers';
import { USER_TOKEN } from '@/constants/global';
import { isMobilePhone } from '@/constants/regexp';
import { login, loginByNode } from '@/services/user';
import { getSmsCode } from '@/services/sms';
import ResetPassword from './reset-password';
import ErrorContent from './error-content';
import Register from './register';
import styles from './index.module.scss';
interface LogoProps {
  loginCallback?: () => any;
}

interface ILoginState {
  mobile: string;
  vcode: string;
  account: string;
  password: string;
}

interface ICanClickState {
  mobileLogin: boolean;
  accountLogin: boolean;
}

interface IMobileLoginParams {
  code: string;
  login_type: 'phone_code';
  phone: string;
}
interface IAccountLoginParams {
  login: string;
  login_type: 'password';
  password: string;
}
type TLoginStatus = 'login' | 'resetPassword' | 'register';

export default function UserLogin({ loginCallback }: LogoProps) {
  const { dispatch } = initStore();
  const { userLoginVisible, loginType } = useSelector<RootState, RootState['modal']>(state => state.modal);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useSetState<any>({
    mobileLoading: false,
    accountLoading: false,
  });
  const [loginState, setLoginState] = useSetState<ILoginState>({
    mobile: '',
    vcode: '',
    account: '',
    password: '',
  });
  const [params, setParams] = useState(null);
  const [loginStatus, setLoginStatus] = useState<TLoginStatus>(loginType);
  // const [errVisible, setErrVisible] = useState(false);

  const onMobileChange = useCallback(
    value => {
      setLoginState({ mobile: value });
    },
    [setLoginState]
  );
  const onVcodeChange = useCallback(
    value => {
      setLoginState({ vcode: value });
    },
    [setLoginState]
  );
  const onAccountChange = useCallback(
    value => {
      setLoginState({ account: value });
    },
    [setLoginState]
  );
  const onPasswordChange = useCallback(
    value => {
      setLoginState({ password: value });
    },
    [setLoginState]
  );
  // const { run } = useRequest(
  //   // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
  //   (data: { phone: string; type: string }) => {
  //     return postSendCode(data);
  //   },
  //   {
  //     formatResult(res) {
  //       return res;
  //     },
  //     manual: true,
  //   }
  // );
  const sendCodeHandle = useCallback(async data => {
    try {
      await getSmsCode(data);
    } catch (error) {
      return error;
    }
  }, []);
  const [error, setError] = useState(null);
  const {
    run: loginRun,
    // data,
  } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    (data: IMobileLoginParams | IAccountLoginParams) => {
      setParams(data);
      return login(data);
    },
    {
      onSuccess: async res => {
        console.log('params :>> ', params);
        await loginByNode(params);
        dispatch({ type: 'user/setToken', payload: res.data.token });
        dispatch({ type: 'user/setUserInfo', payload: res.data });
        localStorage.setItem(USER_TOKEN, res.data.token);
        dispatch({ type: 'modal/closeUserLoginModal' });
        loginCallback && loginCallback();

        // localStorage.setItem(USER_TOKEN, res.data.token);
        // location.reload();
        // setLoading({ accountLoading: false, mobileLoading: false });
      },
      onError: err => {
        setError(err);
        setLoading({ accountLoading: false, mobileLoading: false });
      },
      manual: true,
    }
  );
  const [canClick, setCanClick] = useSetState<ICanClickState>({
    mobileLogin: false,
    accountLogin: false,
  });

  // useEffect(() => {
  //   setErrVisible(Boolean(error?.message !== ''));
  // }, [error]);

  useEffect(() => {
    setCanClick({ mobileLogin: Boolean(loginState.mobile && loginState.vcode) });
  }, [loginState.mobile, loginState.vcode, setCanClick]);
  useEffect(() => {
    // setErrVisible(false);
    setCanClick({ accountLogin: Boolean(loginState.account && loginState.password) });
  }, [loginState.account, loginState.password, setCanClick]);

  const mobileLogin = useCallback(() => {
    if (!canClick.mobileLogin || loading.mobileLoading) return;
    setLoading({ mobileLoading: true });
    loginRun({ code: loginState.vcode, login_type: 'phone_code', phone: loginState.mobile });
  }, [canClick.mobileLogin, loading.mobileLoading, loginRun, loginState.mobile, loginState.vcode, setLoading]);

  const accountLogin = useCallback(() => {
    if (!canClick.accountLogin || loading.accountLoading) return;
    setLoading({ accountLoading: true });
    loginRun({ login: loginState.account, login_type: 'password', password: loginState.password });
  }, [canClick.accountLogin, loading.accountLoading, loginRun, loginState.account, loginState.password, setLoading]);

  const OtherChoice = () => {
    return (
      <div className={styles.otherChoice}>
        <div className={styles.register}>
          还没有账号？去{' '}
          <a style={{ color: '#f75c2f' }} onClick={() => setLoginStatus('register')}>
            注册
          </a>
        </div>
        <div className={styles.moreChoose}>
          <Divider text="您还可以选择" style={{ margin: '4px 0 12px' }} backgroundColor="#fff" />
        </div>
        <a href="javascript:void(0)" title="wechat_login" className={styles.wexinlogin} onClick={loginInwechat}>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-login_wechat"></use>
          </svg>
        </a>
      </div>
    );
  };
  const tabSet = {
    手机验证登录: (
      <>
        <Input
          className={styles.input}
          value={loginState.mobile}
          onChange={onMobileChange}
          placeholder="请输入手机号"
        />
        <Input
          className={styles.input}
          value={loginState.vcode}
          onChange={onVcodeChange}
          placeholder="请输入验证码"
          rightSideSlot={
            <CountDown
              canSend={loginState.mobile && isMobilePhone.test(loginState.mobile)}
              onSend={() => {
                sendCodeHandle({ phone: loginState.mobile, type: 'none' });
              }}
            />
          }
        />
        <ErrorContent errText={error?.message} style={{ marginTop: '8px' }} />
        <Button type={canClick.mobileLogin ? 'normal' : 'default'} style={{ marginTop: '24px' }} onClick={mobileLogin}>
          {loading.mobileLoading ? (
            <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadingImg} />
          ) : (
            '登录'
          )}
        </Button>
        <a className={styles.forgetPassword} onClick={() => setLoginStatus('resetPassword')}>
          忘记密码
        </a>
        <OtherChoice />
      </>
    ),
    账号密码登录: (
      <>
        <Input
          className={styles.input}
          value={loginState.account}
          onChange={onAccountChange}
          placeholder="请输入手机号或账号"
        />
        <Input
          type="password"
          className={styles.input}
          value={loginState.password}
          onChange={onPasswordChange}
          placeholder="请输入密码"
        />
        <ErrorContent errText={error?.message} style={{ marginTop: '8px' }} />
        <Button
          type={canClick.accountLogin ? 'normal' : 'default'}
          style={{ marginTop: '24px' }}
          onClick={accountLogin}
        >
          {loading.accountLoading ? (
            <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadingImg} />
          ) : (
            '登录'
          )}
        </Button>
        <a className={styles.forgetPassword} onClick={() => setLoginStatus('resetPassword')}>
          忘记密码
        </a>
        <OtherChoice />
      </>
    ),
  };

  const handleOk = () => {
    // setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      dispatch({ type: 'modal/closeUserLoginModal' });
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    dispatch({ type: 'modal/closeUserLoginModal' });
  };
  useEffect(() => {
    setLoginStatus(loginType);
  }, [loginType, userLoginVisible]);
  return (
    <div className={classNames(styles.userLogin)}>
      <Modal
        visible={userLoginVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <form
          name="user-login"
          className={classNames('flex-column-nowrap', 'flex-center-center', styles.userLoginContent)}
        >
          <img
            className={styles.userLoginIcon}
            src="//assets-oss.cbndata.com/cbndata-refactor-fe/FnuIjHWHuYVLWHJP7c59PDq3YUiO.png"
          />
          {loginStatus === 'login' ? (
            <Tabs
              tabData={tabSet}
              onSelectChanged={() => {
                setError(null);
              }}
            />
          ) : loginStatus === 'resetPassword' ? (
            <ResetPassword sendCode={sendCodeHandle} loginCallback={loginCallback} />
          ) : (
            <Register sendCode={sendCodeHandle} loginCallback={loginCallback} />
          )}
        </form>
      </Modal>
    </div>
  );
}
