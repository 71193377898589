import React, { useCallback, useState, useRef, useEffect } from 'react';
import Head from 'next/head';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import type { LinkProps } from 'next/link';
import dayjs from 'dayjs';
import animejs from 'animejs';
import { useRequest } from 'ahooks';
import { RootState } from '@/models/index';
import { throttle } from '@/utils/event-control';
import { fadeInOutSearch } from '@/utils/inputanimate';
import NavigationBar, { INavigationBarProps } from '@/components/navigation-bar';
import RightSideTools from '@/components/right-side-tools';
// import SearchComponent from '@/components/search';
import { SITE_TITLE } from '@/constants/env';
import Advertise from '@/components/advertise';
// import Tags, { ITagSearch } from '@/components/tags-antd';
import UserLogin from '@/business-components/user-login';
import BindWechat from '@/business-components/user-login/bind-wechat';
import { getAdvertiseList } from '@/services/advertise';
import styles from './index.module.scss';

export interface ILayoutProps extends INavigationBarProps {
  title?: string;
  keyword?: string;
  description?: string;
  children?: React.ReactNode | React.ReactNode[];
  isShowNavBar?: boolean;
  navBarHasShadow?: boolean;
  // searchTagList?: ITagSearch[];
  hasRightsidetools?: boolean;
  // 是否收紧 内容主题
  isFrapMain?: boolean;
  FooterComponent?: () => JSX.Element;
  mainClass?: string;
  loginCallback?: () => any;
}

export interface ISilderItem {
  icon: JSX.Element | string;
  label: string;
  href: LinkProps['href'];
}

export default function Layout(props: ILayoutProps) {
  const [isIndex, setIsIndex] = useState(true);
  const {
    title = SITE_TITLE,
    keyword,
    description,
    children,
    isShowNavBar,
    isFrapMain,
    navBarHasShadow,
    hasRightsidetools = true,
    FooterComponent,
    mainClass,
    loginCallback,
  } = props;
  const [showAdvertise, setShowAdvertise] = useState(true);
  const [height, setHeight] = useState<number>(0);
  // const [homeSeachHeight] = useState(0);
  const { userLoginVisible } = useSelector<RootState, RootState['modal']>(state => state.modal);
  const advertiseRef = useRef(null);
  // const contentRef = useRef(null);
  // const searchContent = useRef(null);
  // const homeSeachContent = useRef(null);
  const searchRef = useRef(null);
  const headerFixed = useRef(null);
  const { data: advertise } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    () => {
      return getAdvertiseList({ type: 1 });
    },
    {
      formatResult: res => {
        return res.data?.type2Portal?.flashAD ? res.data?.type2Portal?.flashAD[0] : null;
      },
    }
  );

  const [viewAdvertise, setViewAdvertise] = useState(false);

  const onAdvertiseClosed = useCallback(() => {
    setHeight(0);
    setShowAdvertise(false);
  }, []);
  const onTimeEnd = useCallback(() => {
    setHeight(0);
    setShowAdvertise(false);
  }, []);
  const judgeScroll = useCallback(() => {
    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > 120) {
      isIndex &&
        animejs({
          targets: headerFixed.current,
          height: '0', // -> from '28px' to '100%',
          easing: 'easeInOutQuad',
          duration: 200,
          complete: () => {},
        });
      fadeInOutSearch(searchRef.current, 'show');
      // fadeInOut(homeSeachContent.current, `${homeSeachHeight}px`, 'hide');
    } else {
      isIndex &&
        animejs({
          targets: headerFixed.current,
          height: '226px', // -> from '28px' to '100%',
          easing: 'easeInOutQuad',
          duration: 200,
          complete: () => {},
        });
      fadeInOutSearch(searchRef.current, 'hide');
      // fadeInOut(homeSeachContent.current, `${homeSeachHeight}px`, 'show');
    }
  }, [isIndex]);
  useEffect(() => {
    setIsIndex(location.pathname === '/');
    if (location.pathname !== '/') {
      searchRef.current.style.width = 'auto';
      searchRef.current.style.opacity = '1';
    }
    if (isIndex && advertise?.imgUrl) {
      const imgItem = new Image();
      imgItem.src = advertise?.imgUrl;
      imgItem.addEventListener('load', () => {
        const viewed_time = localStorage.getItem('ad_view_time');
        if (viewed_time && dayjs(viewed_time)) {
          if (dayjs().format('YYYY-MM-DD') > dayjs(viewed_time).format('YYYY-MM-DD')) {
            setViewAdvertise(true);
            setHeight(advertiseRef.current?.scrollHeight);
          } else {
            setViewAdvertise(false);
            setHeight(0);
          }
        } else {
          setViewAdvertise(true);
          setHeight(advertiseRef.current?.scrollHeight);
        }
        localStorage.setItem('ad_view_time', dayjs().format('YYYY-MM-DD'));
      });
    }
  }, [advertise?.imgUrl, isIndex, setViewAdvertise]);
  useEffect(() => {
    if ((!viewAdvertise || !showAdvertise) && location.pathname === '/') {
      judgeScroll();
      document.addEventListener(
        'scroll',
        throttle(() => {
          judgeScroll();
        }, 250)
      );
    }
  }, [isIndex, judgeScroll, showAdvertise, viewAdvertise]);

  return (
    <>
      <Head>
        {title ? <meta name="title" content={title} /> : null}
        {description ? <meta name="description" content={description} /> : null}
        {keyword ? <meta name="keywords" content={keyword} /> : null}
        <title>{title}</title>
      </Head>
      <nav className={styles.headnerSection}>
        <div
          className={classNames(styles.advertiseSection)}
          style={{ height: isIndex ? height : 0 }}
          ref={advertiseRef}
        >
          <div style={isIndex && viewAdvertise ? { visibility: 'visible' } : { visibility: 'hidden' }}>
            {viewAdvertise && (
              <Advertise
                imageUrl={advertise?.imgUrl}
                link={advertise?.link}
                onClosed={onAdvertiseClosed}
                cooldown={{ time: 5000, onTimeEnd: onTimeEnd }}
              />
            )}
          </div>
        </div>
        <div className={styles.searchSection}>
          {isShowNavBar ? (
            <NavigationBar className={classNames(styles.headerBar)} isShadow={navBarHasShadow} inputRef={searchRef} />
          ) : null}
        </div>
      </nav>
      <main className={classNames(styles.bodyContent, { [styles.frap]: isFrapMain }, mainClass)}>{children}</main>
      {FooterComponent ? <FooterComponent /> : null}
      {hasRightsidetools && <RightSideTools />}
      {userLoginVisible && <UserLogin loginCallback={loginCallback} />}
      <BindWechat />
    </>
  );
}
