import { GetSmsCodeRequestParams } from '@/interface/sms';
import axios from '@/utils/request';

// 获取短信验证码
export async function getSmsCode(data: GetSmsCodeRequestParams) {
  return axios.request<Record<string, never>>({
    url: '/v2/sms',
    method: 'post',
    data,
  });
}
