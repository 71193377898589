import React, { useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { useSetState, useRequest } from 'ahooks';
import Checkbox from 'rc-checkbox';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import Input from '@/components/input';
import CountDown from '@/components/count-down';
import { isMobilePhone } from '@/constants/regexp';
import Button from '@/components/button';
import { USER_TOKEN } from '@/constants/global';
import { signup } from '@/services/user';
import UserInfoFormModalContent from '@/business-components/user-info-form-modal-content';
import ErrorContent from '../error-content';
import styles from './index.module.scss';
interface IRegisterState {
  mobile: string;
  vcode: string;
  password: string;
}

export default function Register({ sendCode, loginCallback }) {
  const dispatch = useDispatch();
  const router = useRouter();
  console.log('router :>> ', router);
  const { run, error, loading } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    data => {
      return signup(data);
    },
    {
      onSuccess(res) {
        dispatch({ type: 'user/setToken', payload: res.data.token });
        dispatch({ type: 'user/setUserInfo', payload: res.data });
        localStorage.setItem(USER_TOKEN, res.data.token);
        dispatch({ type: 'modal/closeUserLoginModal' });
        !router.pathname.match('cbndatabox-new') &&
          dispatch({ type: 'modal/open', payload: { content: <UserInfoFormModalContent /> } });
        loginCallback && loginCallback();
        // location.reload();
      },
      manual: true,
    }
  );
  const [registerState, setForgetState] = useSetState<IRegisterState>({
    mobile: '',
    vcode: '',
    password: '',
  });
  const [errorText, setErrorText] = useState('');
  const [canClick, setCanClick] = useState(false);
  const [protocolChecked, setProtocolChecked] = useState(true);
  useEffect(() => {
    if (
      registerState.password.length > 0 &&
      (registerState.password.length < 6 || registerState.password.length > 20)
    ) {
      setErrorText('密码长度在6~20位之间');
    } else {
      setErrorText('');
    }
  }, [registerState.password]);
  useEffect(() => {
    // setErrVisible(false);
    setCanClick(
      Boolean(!errorText && registerState.mobile && registerState.vcode && registerState.password && protocolChecked)
    );
  }, [errorText, registerState.mobile, registerState.password, registerState.vcode, protocolChecked, setCanClick]);
  const registerdHandle = useCallback(() => {
    if (!canClick) return;
    run({ user: { password: registerState.password, phone: registerState.mobile, phone_code: registerState.vcode } });
  }, [canClick, registerState.mobile, registerState.password, registerState.vcode, run]);
  return (
    <div className={styles.register}>
      <Input
        className={styles.input}
        value={registerState.mobile}
        onChange={value => setForgetState({ mobile: value })}
        placeholder="请输入手机号"
      />
      <Input
        className={styles.input}
        value={registerState.vcode}
        onChange={value => setForgetState({ vcode: value })}
        placeholder="请输入验证码"
        rightSideSlot={
          <CountDown
            canSend={registerState.mobile && isMobilePhone.test(registerState.mobile)}
            onSend={async () => {
              const error = await sendCode({ phone: registerState.mobile, type: 'new' });
              error && setErrorText(error?.message);
            }}
          />
        }
      />
      <Input
        className={styles.input}
        value={registerState.password}
        onChange={value => setForgetState({ password: value })}
        placeholder="6位以上密码"
      />
      <ErrorContent errText={errorText || error?.message} style={{ marginTop: '8px' }} />
      <Button type={canClick ? 'normal' : 'default'} style={{ margin: '24px 0 0' }} onClick={registerdHandle}>
        {loading ? (
          <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadingImg} />
        ) : (
          '注册'
        )}
      </Button>
      <div className={classNames('flex-row-nowrap', 'flex-center-center', styles.userProtocol)}>
        <Checkbox
          checked={protocolChecked}
          onChange={(e: any) => {
            setProtocolChecked(e.target.checked);
          }}
        />
        <div className={styles.protocolText}>我已阅读并同意</div>
        <a href="/register_agreement" target="_blank" className={styles.protocolLink}>
          注册协议
        </a>
        &nbsp;
        <a href="/privacy" target="_blank" className={styles.protocolLink}>
          隐私协议
        </a>
      </div>
    </div>
  );
}
