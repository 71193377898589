export const isMobilePhone = /^1[3-9][0-9]\d{8}$/;

export const isEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/; // 包含下划线的输入邮箱

export const isCompany = /[\u4e00-\u9fa5]/; // 业务方希望企业名称至少包含1个中文字符

export const isSafePassword = /^(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}$/; // 必须包含数字和字母且8-20位

export const isSafeIdCard = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;

export const isSafeBankCard = /^[1-9]\d{9,29}$/;
