import React from 'react';
import { Tag } from '@/interface/tag';
import { KOL } from '@/interface/kol';
import { Activity } from '@/interface/activity';

export type SubscribeModalValue = {
  subEmail: string;
  tagLevelId: string;
  tagName: string;
  type: 'subscribeReport' | 'subscribeTag' | 'unsubscribeTag';
  isOpen: boolean;
};

export type IndexContextType = SubscribeModalValue & {
  setSubscribeModal: (
    patch: Partial<SubscribeModalValue> | ((prevState: SubscribeModalValue) => Partial<SubscribeModalValue>)
  ) => void;
  resetSubscribeModal: () => void;
  activity: Activity[];
  kols: KOL[];
  trendingTags: Tag[][];
  refreshTrendingTagsList: () => Promise<Tag[][]>;
};

const IndexContext = React.createContext<IndexContextType>({
  activity: [],
  kols: [],
  trendingTags: [],
  refreshTrendingTagsList: () => new Promise(() => []),
  subEmail: '',
  tagLevelId: '',
  tagName: '',
  type: 'subscribeReport',
  isOpen: false,
  setSubscribeModal: () => {},
  resetSubscribeModal: () => {},
});

export default IndexContext;
