import React, { useState, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

const Tabs = (props: { tabData: { [key: string]: ReactNode }; onSelectChanged: () => void }): any => {
  const { tabData, onSelectChanged } = props;
  const arr = Object.keys(tabData); // 由tab标签名组成的数组
  const [selected, setSelected] = useState(arr[0]); // 当前选中的tab标签

  function select(item) {
    setSelected(item);
    onSelectChanged();
  }
  return (
    <div className={styles.tabs}>
      <div className={classNames('flex-row-nowrap', 'flex-around-center', styles.tabsHeader)}>
        {arr.map(item => (
          <div
            key={item}
            className={
              item === selected ? classNames(styles.selectItemBasic, styles.selectItemActive) : styles.selectItemBasic
            }
            onClick={() => select(item)}
          >
            {item}
          </div>
        ))}
      </div>
      {arr.map((item, index) => (
        <div
          className={item === selected ? classNames(styles.tabsBody, styles.tabsBodyShow) : styles.tabsBody}
          key={index}
        >
          {tabData[item]}
        </div>
      ))}
    </div>
  );
};
export default Tabs;
