import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';

export interface INavLink extends LinkProps {
  children?: React.ReactElement;
  className?: string;
  activeClassName?: string;
  isLoose?: boolean;
  linkSet?: LinkProps['href'][];
}

const NavLink = (props: INavLink) => {
  const { children, href, activeClassName, className, isLoose = true, linkSet, ...rest } = props;
  const { pathname } = useRouter();
  const path = useMemo(() => (typeof href === 'string' ? href : href.pathname || href.path || href.href), [href]);
  const isActive = useMemo(() => {
    if (linkSet && Array.isArray(linkSet)) {
      return linkSet.some(l => {
        return isLoose ? pathname.indexOf(l as string) === 0 : pathname === l;
      });
    } else {
      return isLoose && path !== '/' ? pathname.indexOf(path) === 0 : pathname === path;
    }
  }, [isLoose, linkSet, path, pathname]);

  const targetClassName = useMemo(() => {
    let ret = classNames(className);
    if (activeClassName) {
      ret = classNames({ [activeClassName]: isActive }, className);
    }
    return ret;
  }, [activeClassName, className, isActive]);
  return (
    <Link href={href} {...(rest as any)}>
      {React.cloneElement(children, { className: targetClassName })}
    </Link>
  );
};

export default NavLink;
