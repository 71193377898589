import React, { ReactNode, useState, useCallback } from 'react';
import classNames from 'classnames';
import type { InputHTMLAttributes } from 'react';
import styles from './index.module.scss';

type IInputProps = {
  rightSideSlot?: ReactNode;
  value?: string;
  className?: string;
  onChange?: (value: string) => any;
} & InputHTMLAttributes<HTMLInputElement>;

export default function Input({ rightSideSlot, value, onChange, className, ...props }: IInputProps) {
  const [valueData, setValueData] = useState(value);
  const onChangeHandle = useCallback(
    inputVal => {
      setValueData(inputVal);
      onChange?.(inputVal);
    },
    [onChange]
  );
  return (
    <div className={classNames('flex-row-nowrap', styles.input, className)}>
      <input value={valueData} {...props} onChange={e => onChangeHandle(e.target.value)} />
      {rightSideSlot && <div className={styles.rightSide}>{rightSideSlot}</div>}
    </div>
  );
}
