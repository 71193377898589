import React, { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useRequest } from 'ahooks';
import { useSelector } from 'react-redux';
import { RootState } from '@/models/index';
// import { IS_DAILY, IS_DEV, IS_PROD } from '@/constants/env';
import { throttle } from '@/utils/event-control';
import { fetchMessageCheck, fetchMessageCount, userMessage } from '@/services/message';
import type { Activity } from '@/interface/activity';
import styles from './index.module.scss';
import CouponIcon from 'assets/coupon_icon.svg';
import Answer from 'assets/answer.svg';
import ActivityIcon from 'assets/activity_icon.svg';
import GetReportIcon from 'assets/get_report_icon.svg';
export type ActivityCardProps = Pick<Activity, 'activityId' | 'title' | 'cover' | 'createTime'> & {
  className?: string | undefined;
};

// const THRESHOLD_DISTANCE = 100;
function dealReportLink(type, href, report_id) {
  if (type === 'for_value_invitation_accept') {
    return `/report/${report_id}/detail`;
  }
  return href;
}

function joinQuery(type, activity_price_category) {
  return /participation|activity/.test(type) ? `&price_category=${activity_price_category}` : '';
}

const categoryMapInfo = {
  for_new_coupon: {
    title: '恭喜您获得优惠券！',
    href: '/usercenter/?type=coupon',
    icon: <CouponIcon />,
  },
  for_expiring_coupon: {
    title: '优惠券临近过期',
    href: '/usercenter/?type=coupon',
    icon: <CouponIcon />,
  },
  for_issue_answer: {
    title: '分析师回复',
    href: '/usercenter/?type=issuse&state=answered',
    icon: <Answer />,
  },
  for_accept_participation: {
    title: '活动审核通过',
    href: '/usercenter/?type=activity',
    icon: <ActivityIcon />,
  },
  for_reject_participation: {
    title: '活动审核拒绝',
    href: '/usercenter/?type=activity',
    icon: <ActivityIcon />,
  },
  for_coming_activity: {
    title: '活动即将开始',
    href: '/usercenter/?type=activity',
    icon: <ActivityIcon />,
  },
  for_value_invitation_accept: {
    title: '获得“赠一得一”报告',
    href: '/report', // 无效的
    icon: <GetReportIcon />,
  },
  for_free_invitation_accept: {
    title: '兑换券到账',
    href: '/usercenter/?type=coupon',
    icon: <CouponIcon />,
  },
};
function ellipsisContent(content) {
  const str = content;
  if (typeof str === 'string') {
    return str.replace(/《(.+)》/, (match, $0) => {
      if ($0 && $0.length > 15) {
        return `《${$0.slice(0, 15)}...》`;
      }
      return match;
    });
  }
  return str;
}
const UserMessage: React.FC<any> = () => {
  // const onClick = useCallback(() => {
  //   const host = IS_DEV || IS_DAILY ? 'staging.cbndata.com' : IS_PROD ? 'www.cbndata.com' : '';
  //   window.open(`https://${host}/activity/${activityId}/detail`);
  // }, []);
  const { token } = useSelector((state: RootState) => state.user);
  const [userMessageList, setUserMessageList] = useState(null);
  const messageRequest = useCallback(async () => {
    const res = await userMessage({ page: 1, per: 5 });
    setUserMessageList(res.data);
  }, []);
  useEffect(() => {
    token && messageRequest();
  }, [messageRequest, token]);

  const { run, data: count } = useRequest(
    () => {
      return fetchMessageCount();
    },
    {
      formatResult(res) {
        return res.data;
      },
      manual: true,
    }
  );
  useEffect(() => {
    run();
  }, [run]);
  const handleScroll = useCallback(e => {
    if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight - 20) {
      // run();
    }
  }, []);
  return (
    <div className={styles.userMessageContent}>
      <div className={styles.noteContainer} onScroll={e => throttle(handleScroll(e), 200)}>
        {userMessageList?.length < 1 ? <div className={styles.textContent}>无消息通知</div> : null}
        {userMessageList?.map((datum, key) => {
          const { href, title, icon } = categoryMapInfo[datum.category] || {};
          return (
            <a
              onClick={() => !datum.checked && fetchMessageCheck(datum.category.split('_')[2])}
              href={`${dealReportLink(datum.category, href, datum.invitation_report_product_id)}${joinQuery(
                datum.category,
                datum.activity_price_category
              )}`}
              className={`note-item${datum.checked ? ' checked' : ''}`}
              key={key}
            >
              <span className={'note-icon'}>{icon}</span>
              <h4 className="title">{datum.title || title}</h4>
              <p className="desc">{ellipsisContent(datum.content)}</p>
              <div className="date">{dayjs(datum.created_at).format('YYYY.MM.DD')}</div>
            </a>
          );
        })}
      </div>
      {userMessageList?.length > 0 ? (
        <div className={classNames('already-btn', { disabled: count < 1 })} onClick={() => fetchMessageCheck('all')}>
          全部已读
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default UserMessage;
