import React, { useCallback, useRef } from 'react';
import { Select } from 'antd';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import Form, { Field } from 'rc-field-form';
import { useDispatch } from 'react-redux';
import { INDUSTRY_OPTIONS, USER_INFO_FORM_LABEL_NAME_MAP } from '@/constants/global';
import LabelField from '../label-field';
import styles from './index.module.scss';

const emailReg =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const UserInfoForm: React.FC<Record<string, null>> = () => {
  const [form] = Form.useForm();
  const nameInputRef = useRef<HTMLInputElement>();
  nameInputRef.current && nameInputRef.current.focus();
  const dispatch = useDispatch();
  const submit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      await dispatch({ type: 'user/patchUserInfo', payload: values });
      dispatch({ type: 'modal/close' });
      toast.success('提交成功');
    } catch (e) {
      console.log(e);
    }
  }, [dispatch, form]);
  return (
    <Form form={form} preserve={false}>
      <LabelField
        name="name"
        label="姓名"
        rules={[
          {
            async validator(_, value) {
              if (value === '' || value === undefined) {
                return Promise.resolve();
              } else if (
                /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu.test(value) ||
                /^(\s+(.*)\s*)|(\s*(.*)\s+)$/.test(value)
              ) {
                return Promise.reject('请输入正确的姓名');
              } else if (
                (/^(.*)\p{Unified_Ideograph}+(.*)$/u.test(value) && value.length >= 2) ||
                (/^([A-Za-z0-9]*)|([A-Za-z0-9]+(\s)*[A-Za-z0-9])$/.test(value) && value.length >= 4)
              ) {
                return Promise.resolve();
              } else if (
                (/^(.*)\p{Unified_Ideograph}+(.*)$/u.test(value) && value.length < 2) ||
                (/^([A-Za-z0-9]+((\w|\s)*)[A-Za-z0-9]+)|[A-Za-z0-9]+$/.test(value) && value.length < 4)
              ) {
                return Promise.reject('请输入完整的姓名');
              }
              return Promise.reject('请输入正确的姓名');
            },
          },
        ]}
      >
        <input className={styles.fieldInput} ref={nameInputRef} placeholder="请输入您的姓名" />
      </LabelField>
      <LabelField name="industry" label="行业">
        <Select className={styles.fieldSelect} size="large" placeholder="请选择行业" options={INDUSTRY_OPTIONS} />
      </LabelField>
      <LabelField
        name="company"
        label="公司名称"
        required
        rules={[
          { required: true, message: '公司名称不能为空' },
          {
            async validator(_, value) {
              if (
                /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu.test(value) ||
                /^(\s+(.*)\s*)|(\s*(.*)\s+)$/.test(value)
              ) {
                return Promise.reject('请输入正确的公司名称');
              } else if (
                (/^(.*)\p{Unified_Ideograph}+(.*)$/u.test(value) && value.length >= 2) ||
                (/^([A-Za-z0-9]*)|([A-Za-z0-9]+(\s)*[A-Za-z0-9])$/.test(value) && value.length >= 4)
              ) {
                return Promise.resolve();
              } else if (
                (/^(.*)\p{Unified_Ideograph}+(.*)$/u.test(value) && value.length < 2) ||
                (/^([A-Za-z0-9]+((\w|\s)*)[A-Za-z0-9]+)|[A-Za-z0-9]+$/.test(value) && value.length < 4)
              ) {
                return Promise.reject('请输入完整的公司名称');
              }
              return Promise.reject('请输入正确的公司名称');
            },
          },
        ]}
      >
        <input className={styles.fieldInput} placeholder="请输入您的公司名称" />
      </LabelField>
      <LabelField name="job" label="职位" required rules={[{ required: true, message: '职位不能为空' }]}>
        <input className={styles.fieldInput} placeholder="请输入您的职位" />
      </LabelField>
      <LabelField name="wechatAccount" label="微信">
        <input className={styles.fieldInput} placeholder="请输入您的微信" />
      </LabelField>
      <LabelField name="email" label="邮箱" rules={[{ pattern: emailReg, message: '邮箱填写错误' }]}>
        <input className={styles.fieldInput} placeholder="请输入您的邮箱" />
      </LabelField>
      <div className={styles.formTips}>
        其中<span>*</span>为必填选项
      </div>
      <Field shouldUpdate>
        {(_, __, { getFieldsError }) => {
          const errorFields = getFieldsError()
            .filter(({ errors }) => errors.length)
            ?.map(item => item.name[0]);
          const errorTips = errorFields
            .map(item => USER_INFO_FORM_LABEL_NAME_MAP[item])
            .reduce((acc, cur, index) => {
              if (index === errorFields.length - 1) {
                return (acc += cur);
              }
              return (acc += `${cur}、`);
            }, '');
          return (
            <div className={classnames(styles.errorTipsWrapper, { [styles['errorTipsWrapper--visible']]: errorTips })}>
              <svg className={styles.validateErrorIcon} aria-hidden="true">
                <use xlinkHref="#icon-validate_error" />
              </svg>
              <div className={styles.errorTips}>{errorTips}填写错误</div>
            </div>
          );
        }}
      </Field>
      <Field shouldUpdate>
        {(_, __, { getFieldsError, isFieldsTouched }) => {
          const isAllTouched = isFieldsTouched(['company', 'job'], true);
          const hasErrors = !!getFieldsError().filter(({ errors }) => errors.length).length;

          return (
            <button className={styles.submitBtn} type="submit" disabled={!isAllTouched || hasErrors} onClick={submit}>
              完成
            </button>
          );
        }}
      </Field>
    </Form>
  );
};

export default UserInfoForm;
