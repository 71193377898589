import React from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface IButtonProps {
  onClick?: () => any;
  type?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  width?: number;
  height?: number;
}

export default function Button({ onClick, children, style, type, width, height }: IButtonProps) {
  return (
    <div
      className={
        type === 'default' ? classNames(styles.button, styles.default) : classNames(styles.button, styles.normal)
      }
      style={{ width: `${width}px`, height: `${height}px`, lineHeight: `${height}px`, ...style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
