import React, { useContext, useCallback } from 'react';
import classNames from 'classnames';
import { Popover } from 'antd';
// import NextCookies from 'next-cookies';
import { useDispatch, useSelector } from 'react-redux';
import UserMessageComponent from '@/business-components/user-message';
import SearchComponent from '@/components/search';
// import { InputAnimate } from '@/utils/inputanimate';
import IndexContext from '@/context/IndexContext';
import { RootState } from '@/models';
// import UserInfoFormModalContent from '@/business-components/user-info-form-modal-content';
import Logo from '../logo';
import NavLink from '../nav-link';
// import IconFont from '../icon-font';
import styles from './index.module.scss';

export const NAV_LIST = [
  {
    title: '首页',
    href: '/',
  },
  {
    title: '报告',
    href: '/report',
  },
  {
    title: '看点',
    href: '/information',
  },
  {
    title: '榜单',
    href: '/ranklist',
    notice: 'NEW',
  },
  {
    title: '活动',
    href: '/activity',
  },
  {
    title: 'CBNDataBox',
    href: '/cbndatabox-new',
  },
];

export interface INavigationBarProps {
  isShadow?: boolean;
  className?: string;
  inputRef?: any;
}

const PersonalCenterList = ({ handleLoginOut }) => (
  <div className={classNames('felx-column-nowrap', styles.psernalList)}>
    <a className={styles.psernalListItem} href="/usercenter?type=collection" target="_blank">
      我的收藏
    </a>
    <a className={styles.psernalListItem} href="/usercenter?type=coupon" target="_blank">
      订单中心
    </a>
    <a className={styles.psernalListItem} href="/usercenter?type=info" target="_blank">
      个人设置
    </a>
    <a className={styles.psernalListItem} onClick={handleLoginOut}>
      退出登录
    </a>
  </div>
);

export default function NavigationBar(props: INavigationBarProps) {
  // const router = useRouter();
  const dispatch = useDispatch();
  const { resetSubscribeModal, refreshTrendingTagsList } = useContext(IndexContext);
  // const [userMessageList, setUserMessageList] = useState([]);
  // const [showDropdown, setShowDropdown] = useState<boolean>(false);
  // const handleMenuClick = (event: {
  //   key: string;
  //   keyPath: string[];
  //   item: React.ReactInstance;
  //   domEvent: React.MouseEvent<HTMLElement>;
  // }) => {
  //   const { key } = event;
  //   if (key === 'logout') {
  //     jwt.clearAll();
  //     router.replace('/');
  //   }
  // };

  // const menuHeaderDropdown = (
  //   <Menu className={styles.menu} onClick={handleMenuClick}>
  //     <Menu.Item key="logout">退出登录</Menu.Item>
  //   </Menu>
  // );
  const handleLoginOut = useCallback(async () => {
    dispatch({ type: 'user/logout' });
    resetSubscribeModal();
    refreshTrendingTagsList();
  }, [dispatch, refreshTrendingTagsList, resetSubscribeModal]);
  const { token } = useSelector((state: RootState) => state.user);
  // const UserMessage = () => (
  //   <div>
  //     {JSON.stringify(userMessageList)}
  //     {userMessageList && userMessageList.length > 0 ? (
  //       userMessageList?.map((_, index) => <div key={index}></div>)
  //     ) : (
  //       <div>无消息通知</div>
  //     )}
  //   </div>
  // );

  return (
    <header
      className={classNames('flex-row-nowrap', 'flex-between-center', props.className, styles.bar, {
        [styles.barShadow]: props.isShadow,
      })}
    >
      <Logo />
      <div className={classNames('flex-row-nowrap', 'flex-end-center', styles.linkBar)}>
        {NAV_LIST.map(nav => (
          <NavLink
            className={styles.navItem}
            href={nav.href}
            activeClassName={styles.navItemActive}
            key={nav.title}
            // isLoose={false}
          >
            <a>
              {nav.title}
              {nav.notice && <span className={styles.notice}>{nav.notice}</span>}
            </a>
          </NavLink>
        ))}
      </div>
      <div ref={props.inputRef} className={styles.inputContent}>
        <SearchComponent height={32} placeholder="搜索你感兴趣的行业、人群标签" />
      </div>
      <div className={classNames('flex-row-nowrap', 'flex-between-center', styles.userAbout)}>
        {token ? (
          <Popover content={<UserMessageComponent />} placement="bottom" overlayClassName={styles.userMessagePopover}>
            <div className={styles.userMessage}>
              <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-notification_important"></use>
              </svg>
              <span className={styles.text}>消息</span>
            </div>
          </Popover>
        ) : (
          <div className={styles.userMessage}>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-notification_important"></use>
            </svg>
            <span className={styles.text}>消息</span>
          </div>
        )}
        {token ? (
          <Popover
            content={<PersonalCenterList handleLoginOut={handleLoginOut} />}
            placement="bottom"
            overlayClassName={styles.personalContent}
          >
            <div className={styles.userMessage}>
              <svg className="icon" aria-hidden="true">
                <use xlinkHref="#icon-person"></use>
              </svg>
              <a target="_blank" href="/usercenter?type=info" className={styles.text}>
                个人中心
              </a>
            </div>
          </Popover>
        ) : (
          <div className={styles.userMessage}>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-person"></use>
            </svg>
            <span
              className={styles.text}
              onClick={() => {
                // dispatch({ type: 'modal/open', payload: { content: <UserInfoFormModalContent /> } });
                dispatch({ type: 'modal/openUserLoginModal', payload: 'register' });
              }}
            >
              注册
            </span>
            <span className={classNames(styles.text, styles.logingText)}>&nbsp;|&nbsp;</span>
            <span
              className={classNames(styles.text, styles.logingText)}
              onClick={() => {
                dispatch({ type: 'modal/openUserLoginModal', payload: 'login' });
              }}
            >
              登录
            </span>
          </div>
        )}
        {/* <Dropdown
          overlay={menuHeaderDropdown}
          placement="bottomRight"
          overlayClassName={styles.dropdownMenu}
          onVisibleChange={setShowDropdown}
        >
          <div className={classNames(styles.userInfo, 'flex-row-nowrap', 'flex-between-center')}>
            <span className={classNames(styles.userName, 'text-ellipsis')}>昵称</span>
            <IconFont
              type="iconarrow-ios-upward-outline"
              style={{ fontSize: '12px' }}
              className={classNames(styles.arrow, {
                [styles.arrowUp]: showDropdown,
              })}
            />
          </div>
        </Dropdown> */}
      </div>
    </header>
  );
}
