import React from 'react';
// import classNames from 'classnames';
import styles from './index.module.scss';

export type IDividerProps = {
  height?: string;
  color?: string;
  backgroundColor?: string;
  text?: string;
  style?: React.CSSProperties;
};

export default function Divider({ height, color = '#000', backgroundColor = '#000', text = '', style }: IDividerProps) {
  return (
    <div className={styles.divider} style={{ height, color, backgroundColor, ...style }}>
      {text && <div className={styles.text}>{text}</div>}
    </div>
  );
}
