import classNames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
interface IAdvertise {
  title?: string;
  imageUrl: string;
  link: string;
  cooldown?: {
    time: number;
    onTimeEnd?: () => any;
  };
  onClosed?: () => any;
  adIdentifier?: number;
  [key: string]: any;
}
export default function Advertise(props: IAdvertise) {
  const { title, imageUrl, link, cooldown, onClosed, style } = props;
  const [timeNo, setTimeNo] = useState(cooldown ? cooldown.time / 1000 : 0);
  const [showAdvertise, setShowAdvertise] = useState(true);

  const coolDownHandle = useCallback(() => {
    const timer = setTimeout(() => {
      setTimeNo(timeNo - 1);
      if (timeNo - 1 <= 0) {
        clearTimeout(timer);
        setShowAdvertise(false);
        cooldown?.onTimeEnd && cooldown?.onTimeEnd();
      }
    }, 1000);
  }, [cooldown, timeNo]);
  useEffect(() => {
    cooldown && coolDownHandle();
  }, [coolDownHandle, cooldown]);
  return (
    <div className={styles.advertiseContent} style={style}>
      {cooldown && timeNo > 0 && (
        <div
          className={styles.advertiseClose}
          style={showAdvertise ? { display: 'block' } : { display: 'none' }}
          onClick={() => {
            setShowAdvertise(false);
            onClosed();
          }}
        >
          <div className={classNames('flex-row-nowrap', 'flex-start-center')}>
            <svg className="icon" aria-hidden="true">
              <use xlinkHref="#icon-tag_unsubscribe" />
            </svg>
            <span className={styles.closeText}>{timeNo}秒后关闭</span>
          </div>
        </div>
      )}
      {props.adIdentifier === 2 && <div className={styles.adIdentifier}>广告</div>}
      <a href={link} className={styles.advertiseLink} target="_blank" rel="noreferrer" aria-label={title}>
        <img className={styles.advertiseImage} src={imageUrl} alt={title} />
      </a>
    </div>
  );
}
