import React, { useState, useEffect, useCallback } from 'react';
// import classNames from 'classnames';
import { useSetState, useRequest } from 'ahooks';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import { resetPassword } from '@/services/user';
import Input from '@/components/input';
import CountDown from '@/components/count-down';
import { isMobilePhone } from '@/constants/regexp';
import Button from '@/components/button';
import { USER_TOKEN } from '@/constants/global';
import ErrorContent from '../error-content';
import styles from './index.module.scss';
interface IForgetState {
  mobile: string;
  vcode: string;
  password: string;
  confirm_password: string;
}

export default function ResetPassword({ sendCode, loginCallback }) {
  const dispatch = useDispatch();
  const { run, error, loading } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    data => {
      return resetPassword({ user: data });
    },
    {
      onSuccess: async res => {
        dispatch({ type: 'user/setToken', payload: res.data.token });
        dispatch({ type: 'user/setUserInfo', payload: res.data });
        localStorage.setItem(USER_TOKEN, res.data.token);
        dispatch({ type: 'modal/closeUserLoginModal' });
        loginCallback && loginCallback();
        // location.reload();
      },
      manual: true,
    }
  );
  const [forgetState, setForgetState] = useSetState<IForgetState>({
    mobile: '',
    vcode: '',
    password: '',
    confirm_password: '',
  });
  const [errorText, setErrorText] = useSetState({
    errorText1: '',
    errorText2: '',
  });
  const [canClick, setCanClick] = useState(false);
  useEffect(() => {
    if (forgetState.password.length > 0 && (forgetState.password.length < 6 || forgetState.password.length > 20)) {
      setErrorText({ errorText1: '密码长度在6~20位之间' });
    } else {
      setErrorText({ errorText1: '' });
    }
    if (forgetState.confirm_password.length > 0 && forgetState.password !== forgetState.confirm_password) {
      setErrorText({ errorText2: '两次输入的密码不一致' });
    } else {
      setErrorText({ errorText2: '' });
    }
  }, [forgetState.password, forgetState.confirm_password, setErrorText]);
  useEffect(() => {
    // setErrVisible(false);
    setCanClick(
      Boolean(
        !errorText.errorText1 &&
          !errorText.errorText2 &&
          forgetState.mobile &&
          forgetState.vcode &&
          forgetState.password &&
          forgetState.confirm_password
      )
    );
  }, [
    errorText,
    forgetState.confirm_password,
    forgetState.mobile,
    forgetState.password,
    forgetState.vcode,
    setCanClick,
  ]);
  const resetPasswordHandle = useCallback(() => {
    if (!canClick) return;
    run({ password: forgetState.password, phone: forgetState.mobile, phone_code: forgetState.vcode });
  }, [canClick, forgetState.mobile, forgetState.password, forgetState.vcode, run]);
  return (
    <div className={styles.forgetPasswordContent}>
      <Input
        className={styles.input}
        value={forgetState.mobile}
        onChange={value => setForgetState({ mobile: value })}
        placeholder="请输入手机号"
      />
      <Input
        className={styles.input}
        value={forgetState.vcode}
        onChange={value => setForgetState({ vcode: value })}
        placeholder="请输入验证码"
        rightSideSlot={
          <CountDown
            canSend={forgetState.mobile && isMobilePhone.test(forgetState.mobile)}
            onSend={() => {
              sendCode({ phone: forgetState.mobile, type: 'none' });
            }}
          />
        }
      />
      <Input
        className={styles.input}
        value={forgetState.password}
        onChange={value => setForgetState({ password: value })}
        placeholder="6位以上密码"
      />
      <ErrorContent errText={errorText.errorText1} style={{ marginTop: '8px' }} />
      <Input
        className={styles.input}
        value={forgetState.confirm_password}
        onChange={value => setForgetState({ confirm_password: value })}
        placeholder="确认密码"
      />
      <ErrorContent errText={errorText.errorText2} style={{ marginTop: '8px' }} />
      <ErrorContent errText={error?.message} style={{ marginTop: '8px' }} />
      <Button type={canClick ? 'normal' : 'default'} style={{ margin: '24px 0 62px' }} onClick={resetPasswordHandle}>
        {loading ? (
          <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadingImg} />
        ) : (
          '重置密码'
        )}
      </Button>
    </div>
  );
}
