import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import type { TextareaHTMLAttributes } from 'react';
import styles from './index.module.scss';

type IInputProps = {
  value?: string;
  className?: string;
  onChange?: (value: string) => any;
  canResize?: boolean;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export default function TextArea({ value, onChange, className, canResize = false, ...props }: IInputProps) {
  const [valueData, setValueData] = useState(value);
  const onChangeHandle = useCallback(
    inputVal => {
      setValueData(inputVal);
      onChange?.(inputVal);
    },
    [onChange]
  );
  return (
    <div className={classNames('flex-row-nowrap', styles.textarea, className)}>
      <textarea
        value={valueData}
        {...props}
        onChange={e => onChangeHandle(e.target.value)}
        style={{ resize: canResize ? 'both' : 'none' }}
      ></textarea>
    </div>
  );
}
