import { MessageListResponseData } from '@/interface/message';
import axios from '@/utils/request';

export async function userMessage(params) {
  return axios.request<MessageListResponseData, MessageListResponseData>({
    method: 'get',
    url: '/v2/mine/messages',
    params,
  });
}

export async function fetchMessageCount() {
  return axios.request<{ data: number }, { data: number }>({
    url: '/v2/mine/messages/count',
    method: 'get',
  });
}

export async function fetchMessageCheck(type) {
  return axios.request({
    url: '/v2/mine/messages/check',
    method: 'post',
    data: type,
  });
}
