import React, { useState, useCallback } from 'react';
import classNames from 'classnames';
import { useInterval } from 'ahooks';
import styles from './index.module.scss';

interface ICountDownProps {
  time?: number;
  interval?: number;
  onSend: () => any;
  canSend: boolean;
  theme?: 'ghost' | 'block';
}

export default function CountDown({
  time = 60000,
  interval = 1000,
  onSend,
  canSend,
  theme = 'ghost',
}: ICountDownProps) {
  const [timeDown, setTimeDown] = useState(time / 1000);
  const [startCount, setStartCount] = useState(false);
  const [hasSend, setHasSend] = useState(false);

  useInterval(
    () => {
      if (timeDown - 1 <= 0) {
        setStartCount(false);
      } else {
        setTimeDown(timeDown - 1);
      }
    },
    interval,
    { immediate: false }
  );
  const clickHandle = useCallback(() => {
    if (!canSend || startCount) return;
    setHasSend(true);
    setStartCount(true);
    setTimeDown(time / 1000);
    onSend();
  }, [canSend, onSend, startCount, time]);
  return (
    <div className={theme === 'ghost' ? styles.ghost : styles.block}>
      <div
        className={
          !canSend
            ? classNames(styles.countdown, styles.disabled)
            : startCount
            ? classNames(styles.countdown, styles.sending)
            : styles.countdown
        }
        onClick={clickHandle}
      >
        {startCount ? `${timeDown}秒后重新发送` : hasSend ? '重新获取' : '获取验证码'}
      </div>
    </div>
  );
}
